<template>
  <div>
    <!-- 头像加高考分 -->
    <div style="position: relative;">
      <img src="@/assets/imgs/topa.png" alt="" width="100%" height="100%">
      <!-- <div class="gknf">高考年份: {{ year }}</div> -->
      <div class="toux" @click="$router.push({ path: '/individual1' })">
        <div>
          <img src="@/assets/imgs/toux.png" alt="" width="100%" height="100%">
        </div>
      </div>
      <div class="fensi">
        <span v-show="userType == '01'">普通用户</span>
        <span v-show="userType == '04'">自主用户</span>
        <span v-show="userType == '02' || userType == '03' || userType == '05'">智能用户</span>

        <!-- <span>成绩: 580</span> -->
      </div>
      <!-- <div class="yuanx">
        <div class="bor">
          <span>关注院校</span><br>
          <span>0</span>
        </div>
        <div class="bor">
          <span>专注专业</span><br>
          <span>5</span>
        </div>
        <div class="bor">
          <span>文章</span><br>
          <span>20</span>
        </div>
        <div>
          <span>机构</span><br>
          <span>13</span>
        </div>
      </div> -->

    </div>
    <div style="margin-top: 20%;">
      <img src="@/assets/imgs/vipfuone.png" @click="$router.push({ path: '/vip' })" alt="" width="100%" height="100%">

      <div class="text">
        <div @click="myVolunteer" style="margin-left: -17px;"> <img style="width: 16px;height: 14px;" src="@/assets/imgs/vipfutwo.png" alt="">我的志愿表</div>
        <div @click="myOrder"> <img src="@/assets/imgs/vip3.png" alt=""> 我的订单</div>
        <div @click="myhost"><img src="@/assets/imgs/vip5.png" alt=""> 修改历史</div>
        <div @click="lookUserHelp(2)"> <img src="@/assets/imgs/vip4.png" alt=""> 用户协议</div>
        <div @click="lookUserHelp(1)"><img src="@/assets/imgs/vip5.png" alt=""> 隐私政策</div>
        <div  @click="aboutus"> <img src="@/assets/imgs/vip6.png" alt=""> 关于我们</div>
        <!-- <div> <img src="@/assets/imgs/vip7.png" alt=""> 切换账号</div> -->
        <div @click="loginout"> <img src="@/assets/imgs/vip8.png" alt=""> 退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from '@/api/user.js'

export default {
  name: 'comzhx',
  data() {
    return {
      year: '---',
      userType: ''
    }
  },
  mounted() {
    console.log('个人中心mounted')
    let inFo = this.$store.state.userInfo;

    if (inFo.expirationTime) {
      this.year = inFo.expirationTime.slice(0, 4)
    }
    this.userType = this.$store.state.userInfo.userType;
  },
  methods: {
    // 退出登录
    loginout() {

      const self = this
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          logout().then(res => {
            if (res.code == 200) {
              self.$message.success('退出成功')
              localStorage.removeItem('token')
              localStorage.removeItem('phonenumber')
              // 清空vuex
              self.$store.dispatch('resetUserInfo')
              // 回到首页
                 self.$router.push({ path: '/index',query:{islogout:true} })
                 self.$router.go(0)   //刷新页面
            }
          })
        })
        .catch(() => {
          this.$message.warning('取消退出')
        })
    },
    // 用户协议、隐私政策
    lookUserHelp(type) {
      const { href } = this.$router.resolve({
        path: '/UserAgreement',
        query: { type, },
      })
      window.open(href, '_blank')
    },
    myVolunteer() {
      this.$router.push({
        path: '/individual2'
      })
    },
    myOrder() {
      this.$router.push({
        path: '/individual3'
      })
    },
    myhost() {
      this.$router.push({
        path: '/host'
      })
    },
    aboutus() {
      this.$router.push({
        path: '/aboutUs'
      })
    },
  },


}
</script>

<style lang="scss" scoped>
.gknf {
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-top: -10px;
  position: relative;
  font-family: PingFang SC;
  font-weight: 500;
  color: #0e5744;
  top: -14px;
  padding-left: 40%;
}

.toux {
  width: 35%;
  height: 58%;
  position: absolute;
  top: 59%;
  left: 2%;
  z-index: 999;
  border-radius: 50%;
}

.fensi {
  position: absolute;
  top: 95%;
  left: 39.5%;
  font-size: 15px;
  cursor: pointer;
}

.fensi span {
  font-size: 12px;
  display: inline-block;
  background-color: #ffecb6;
  color: #c09f42;
  padding: 3px 6px;
  font-size: 13px;
  font-weight: 500;
  font-family: PingFang SC;
}


.yuanx {
  position: absolute;
  top: 135%;
  left: 6%;
  width: 87%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  text-align: center;
}

.yuanx div {
  padding-right: 10px;
}

.bor {
  border-right: 2px solid #ccc;
}

.yuanx span {
  font-size: 12px;
  font-weight: 400;
  color: #404040;
  display: inline-block;
  margin-top: 10px;
}

.imgs {
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 94%;
  left: 1.7%;
}



.text {
  width: 70%;
  height: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  cursor: pointer;
  // margin-top: -12%;
  margin-left: -9%;
  padding-bottom: 10px;
}
.text img {
  width: 15%;
  margin-top: 12%;
}

.text div {
  margin-top: 5.5%;
}
</style>
