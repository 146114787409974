
<template >
    <div class="zongDiv">
        <!-- 左盒子 -->
        <div class="indLeft">
            <comzhx />
        </div>
        <div class="indRight">

              <router-view></router-view>


        </div>
    </div>
</template>

<script>
import router from '../../router';
import comzhx from './comzhx.vue'
export default {
    name: 'individual',
    data() {
        return {

        }
    },
    components: {
        comzhx,
        router
    },
    methods: {

    },
    mounted() {

    },
    activated(){

    }
}
</script>

<style lang="scss" scoped>
.zongDiv {
    width: 80%;
    margin: 0 auto;
    // height: 900px;
    display: flex;
    justify-content: space-between;
    // border: 1px solid #ccc;
}

.indLeft {
    width: 25%;
    // float: left;
    margin-top: 3%;
    // height: 610px;
    background-color: rgb(238 238 238 / 22%);

}

.indRight {
    width: 72%;
    // height: 90%;
    // border: 1px solid #000000;
    // float: right;
    margin-top: 3%;

    background-color: rgb(238 238 238 / 22%);

}
</style>
